import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'
import 'antd/dist/antd.css';
import {
  Box
} from 'grommet';
import { OPS_DATA_URL } from '../../config';
import { authenticateToken } from "../../utils/auth";


class DeleteDataModalPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  static propTypes = {
    alertVisible: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    alertTitle: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  }

  loadingChangeState(state) {
    this.setState({
      loading: state
    })
  }

  submitDeleteClick = (e) => {
    let authToken = authenticateToken();
    this.loadingChangeState(true);
    e.preventDefault();
    let ids = this.props.fields.ids;
    let obj = {ids};
    let fetchOption = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authToken
      },
      body: JSON.stringify(obj)
    }
    let url = `${OPS_DATA_URL}`
    if (this.props.type === 'Maintenance') {
      url = url + '/maintenances'
    } else {
      url = url + '/incidents'
    }

    fetch(url, fetchOption).then(
      res => res.json().then(result => {
        this.loadingChangeState(false)
        this.props.onSubmit(true)
      }).catch((e) => {
        this.loadingChangeState(false)
        this.props.onSubmit(false)
      })
    )
  }

  render() {
    const { onCancel, alertTitle, alertVisible, idArrChecked } = this.props;
    return (
      <Modal
        visible={alertVisible}
        onCancel={onCancel}
        id="deploy-tenant-modal-container"
        title={alertTitle}
        footer={
          idArrChecked && idArrChecked.length > 0 ? [
            <Button key="back" onClick={onCancel}>Cancel</Button>,
            <Button key="submit" type="primary" loading={this.state.loading} onClick={this.submitDeleteClick}>Delete</Button>,
          ] : [<Button key="back" onClick={onCancel}>Close</Button>]
        }
      >
        <div>
          <Box>
            {idArrChecked && idArrChecked.length > 0 ?
              <span>Are you sure you want to delete?</span>
              : <span>Please select the data to delete.</span>
            }
          </Box>
        </div>
      </Modal>
    );
  };
}

export default DeleteDataModalPage
