import React, { Component } from 'react';
import { Box, Grommet } from 'grommet';
import MUIDataTable from "mui-datatables";
import '../index.css'
import CustomToolbar from './CustomToolbar'

class MUICustomTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      title: this.props.popUptitle,
      isEdit: false,
      poptitle: this.props.popUpEdittitle,
      type: this.props.type
    };
  }

  render() {
    const { title, poptitle, visible } = this.state;
    const { data, columns } = this.props;
    const options = {
      filterType: "dropdown",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <CustomToolbar
            popUptitle={title}
            popUpEdittitle={poptitle}
            visible={visible}
            {...this.props}
          />
        )
      }
    };

    return (
      <Grommet>
        <Box pad="xsmall" basis="xsmall" width="1388px" fill="vertical">
          <MUIDataTable
            title={title}
            data={data}
            columns={columns}
            options={options}
          />
        </Box>
      </Grommet>

    )
  }
}

export default MUICustomTable
