import React, { Component } from 'react';
import { ReactComponent as Logo } from '../icons/opentext-logo_inverse.svg';
import {
  Grommet,
  Box,
  Text,
  ResponsiveContext, Grid
} from "grommet";
import { deepMerge } from 'grommet/utils';
import './app.css';

// import { ReactComponent as LogoWhite } from '../icons/opentext-logo_inverse.svg';
import { brandColor, OPS_DATA_URL, mainPageTitle } from '../config';
import { AmplifySignOut } from '@aws-amplify/ui-react';

const axios = require('axios');

const HeadTheme = deepMerge({
  global: {
    colors: {
      brand: brandColor
    },
  },
  breakpoints: {
    xsmall: {
      value: 400
    },
    small: {
      value: 600,

    },
    medium: {
      value: 700
    },
    large: {
      value: 900
    }
  }
});
const columns = {
  small: ["56px", "auto"],
  medium: ["auto"],
  large: ["auto"],
  xlarge: ["auto"]
};
const rows = {
  small: ["24px", "auto"],
  medium: ["auto"],
  large: ["56px"],
  xlarge: ["56px"]
};
const areas = {
  small: [
    { name: "header", start: [0, 0], end: [1, 0] },
    { name: "farm", start: [0, 1], end: [0, 1] },
    { name: "version", start: [1, 1], end: [1, 1] }
  ],
  medium: [
    { name: "header", start: [0, 0], end: [1, 0] },
    { name: "farm", start: [1, 0], end: [1, 0] },
    { name: "version", start: [2, 0], end: [2, 0] }
  ],
  large: [
    { name: "header", start: [0, 0], end: [0, 0] },
    { name: "farm", start: [1, 0], end: [1, 0] },
    { name: "version", start: [2, 0], end: [2, 0] }
  ],
  xlarge: [
    { name: "header", start: [0, 0], end: [0, 0] },
    { name: "farm", start: [1, 0], end: [1, 0] },
    { name: "version", start: [2, 0], end: [2, 0] }
  ]
};
const ResponsiveGrid = ({
  children,
  overrideColumns,
  overrideRows,
  areas,
  ...props
}) => (
  <ResponsiveContext.Consumer>
    {size => {
      // take into consideration if not array is sent but a simple string
      let columnsVal = columns;
      if (columns) {
        if (columns[size]) {
          columnsVal = columns[size];
        }
      }

      let rowsVal = rows;
      if (rows) {
        if (rows[size]) {
          rowsVal = rows[size];
        }
      }

      // also if areas is a simple array not an object of arrays for different sizes
      let areasVal = areas;
      if (areas && !Array.isArray(areas)) areasVal = areas[size];

      return (
        <Grid
          {...props}
          areas={!areasVal ? undefined : areasVal}
          rows={!rowsVal ? size : rowsVal}
          columns={!columnsVal ? size : columnsVal}
        >
          {children}
        </Grid>
      );
    }}
  </ResponsiveContext.Consumer>
);



class HeadBar extends Component {

  constructor(props) {
    super(props);
    this.state = { farmVersion: "" }
  }

  componentDidMount() {
    if (this.props.farmCode) {
      this._getData(this.props.farmCode);
    }
  }

  async _getData(farmCode) {
    let url = `${OPS_DATA_URL}/configitems/${farmCode}`;
    let response = await axios.get(url);
    let configItem = response.data;
    let farmVersion = configItem.smaxver;
    if (farmVersion) {
      this.setState({ farmVersion });
    }
    // else {
    //   this._getData("global");
    // }
  }

  render() {


    // let codeStyle = {
    //   // marginLeft: '14.5px',
    //   fontSize: "18px",
    //   fontWeight: "500",
    //   fontStyle: "medium",
    //   fontStretch: "normal",
    //   lineHeight: "normal",
    //   letterSpacing: "0.3px",
    //   color: "#ffffff",
    // }

    // let navButton = {
    //   fontFamily: "Arial",
    //   fontSize: "16px",
    //   fontWeight: "normal",
    //   fontStyle: "normal",
    //   fontStretch: "normal",
    //   color: "#ffffff"
    // }

    const { farmVersion } = this.state;

    return (
      <Grommet theme={HeadTheme} >

        <Box background="brand" align="left">
          <Box
            height="56px"
            width="1102px"
            direction="row"
            //justify='between'
            //elevation='xsmall'
            style={{ zIndex: '1' }}
          >
            <Box direction="row" align="center" style={{marginLeft: "2.2%"}}>
              <div id="fullLogo">
                <Logo color="#ffffff" />
              </div>
            </Box>
            <Box direction='row' align="center" margin="0 8px">
              <div style={{borderLeft: '1px solid #FFFFFF', height: '18px'}} />
            </Box>
            <ResponsiveGrid
              rows={rows}
              columns={columns}
              areas={areas}
            >
              <Box direction="row" align='center' id="top" gridArea="header">
                <Text id="title">{mainPageTitle}</Text>
              </Box>

              {
                this.props.farmCode ?
                  <>
                    <Box gridArea="farm" alignSelf='center'>
                      <div className="headbar">
                        <div className='codeStyle'>{this.props.farmCode.toUpperCase()}</div>
                      </div>
                    </Box>
                    <Box gridArea="version" alignSelf="center" >
                      <div className="headbar">
                        <span className='codeStyle'>{farmVersion}</span>
                      </div>
                    </Box>
                  </>
                  :
                  <Box gridArea="farm" alignSelf='center' direction='column' className="signOut">
                    <AmplifySignOut />
                  </Box>
              }
            </ResponsiveGrid>
          </Box>
        </Box>

      </Grommet>
    );
  }
}

export default HeadBar;