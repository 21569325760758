import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'
import 'antd/dist/antd.css';
import { Box } from 'grommet';

class EditDataModalPage extends Component {
  static propTypes = {
    editVisible: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    alertTitle: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  render() {
    const { onCancel, alertTitle, editVisible, idArrChecked } = this.props;
    return (
      <Modal
        visible={editVisible}
        onCancel={onCancel}
        id="deploy-tenant-modal-container"
        title={alertTitle}
        footer={[<Button key="back" onClick={onCancel}>Close</Button>]
        }
      >
        <div>
          <Box>
            {idArrChecked && idArrChecked.length > 1 ?
              <span>You just can choose only one to edit!</span>
              : <span>Please select the data to edit.</span>
            }
          </Box>
        </div>
      </Modal>
    );
  };
}

export default EditDataModalPage