import React, { Component } from "react";
//import { SignIn } from "aws-amplify-react";
import {
  Box, Grommet
} from 'grommet';
import MUICustomTable from '../components/MUI/MUICustomTable';
import { OPS_DATA_URL } from "../config";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import NotificationStatus from '../components/Notification';
import { getLocalUTCTime } from '../utils/CommonUtil';

class Maintenance extends Component {

  initColumns = [
    {
      name: "SELECT",
      options: {
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            value={this.state.checked ? true : false}
            control={
              <Checkbox checked={value}
                onChange={(event) => this.handleCheckBox(tableMeta.rowData, tableMeta.rowData[1], event)}
                value={this.state.checked ? true : false}
                color="default" />
            }
          />
        )
      }
    },
    {
      label: 'id',
      name: "id",
      options: {
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        empty: true,
        display: false
      }
    },
    {
      label: "FARM CODE",
      name: 'farm',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.toUpperCase()
        }
      }
    },
    {
      label: "REPORT NAME",
      name: 'subject'
    },
    {
      label: "CREATION DATE",
      name: 'id',
      options: {
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        display: false
      }
    },
    {
      label: "SUMMARY",
      name: 'desc',
      options: {
        filter: false,
        display: false,
        viewColumns: false
      }
    },
    {
      label: "START TIME",
      name: "start_time",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return getLocalUTCTime(value)
        }
      }
    },
    {
      label: "END TIME",
      name: "end_time",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return getLocalUTCTime(value)
        }
      }
    },
    {
      label: "CHANGE STATUS",
      name: 'ci_status',
      options: {
        filter: false
      }
    },
    {
      label: "Modifier",
      name: "operator",
      options: {
        filter: true,
        viewColumns: true,
        display: false
      }
    }
  ]

  constructor(props) {
    super(props)
    this.refreshMaintenance = this.refreshMaintenance.bind(this)
    this.state = {
      data: [],
      columns: this.initColumns,
      idArrChecked: [],
      checkedRowData: new Map(),
      currRowData: []
    }
  }

  refreshMaintenance = (data) => {
    if (data.visible) {
      this.setState({
        data: [],
        columns: this.initColumns,
        checked: false,
        checkedRowData: new Map(),
        currRowData: [],
        notificationVisible: data.visible,
        text: data.text,
        item: data.item
      })
      this.handleGetMaintenances()
    }
  }

  handleCheckBox = (rowData, id, event) => {
    let checked = event.target.checked;
    // console.log('rowData is ', rowData)
    let rowIndex = id;
    // console.log("rowIndex is ", rowIndex);

    const {checkedRowData, idArrChecked } = this.state;

    if (checked) {
      idArrChecked.push(rowIndex);
      checkedRowData.set(rowIndex, rowData);
    } else {
      idArrChecked.splice(idArrChecked.indexOf(rowIndex), 1);
      checkedRowData.delete(rowIndex);
    }
    // console.log("Now idArrChecked is ", idArrChecked);
    const firstKey = checkedRowData.keys().next().value;
    const currRowData = checkedRowData.get(firstKey);
    this.setState({
      checked,
      currRowData,
      idArrChecked
    })
  }

  handleGetMaintenances() {
    let url = `${OPS_DATA_URL}`;
    let fetchOption = {
      method: 'Get'
    }
    fetch(url + "/maintenances", fetchOption).then(res => {
      if (res.ok) {
        res.json().then(data => {
          this.setState({
            data: data,
            checked: false,
            idArrChecked: [],
            currRowData: []
          })
        })
      }
    })
  }

  componentDidMount() {
    this.handleGetMaintenances()
  }

  render() {
    const { data, columns, idArrChecked, currRowData, notificationVisible, text, item } = this.state;

    return (
      <Grommet>
        {notificationVisible ? <NotificationStatus text={text} type={item} visible={'true'}></NotificationStatus> : null}
        <Box fill="vertical">
          <MUICustomTable
            title={"Maintenance List"}
            data={data}
            columns={columns}
            popUptitle={"Schedule"}
            idArrChecked={idArrChecked}
            rowData={currRowData}
            refresh={this.refreshMaintenance}
            type={"Maintenance"}
          />
        </Box>
      </Grommet>
    );
  }
}

export default Maintenance;
