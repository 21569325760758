import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';

export default function Footer() {
  return (
    <MDBFooter bgColor="light" className='text-center text-lg-left' >
      <div className='text-center p-3' style={{ backgroundColor: '#666666' }}>
        <span className='copyright'>&copy; Copyright {new Date().getFullYear()} OpenText</span>
      </div>
    </MDBFooter>
  );
}