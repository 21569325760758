import React, { Component } from 'react';
import { Form, Input, message, Button } from 'antd';
import { Box } from 'grommet';
import { OPS_DATA_URL } from '../config';
import { getAuthEmailFromToken, authenticateToken } from '../utils/auth';

class InstanceForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      saving: false
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return console.log(err);
      }
      this.setState({ saving: true });
      // console.log('Received values of form: ', values);
      let configItem = values;
      let authToken = authenticateToken();
      let operator = getAuthEmailFromToken();
      configItem.operator = operator;
      configItem.smaxver = "";
      configItem.teams = false;
      if (configItem.farm) {
        configItem.farm = configItem.farm.toLowerCase();
      }
      var fetchOption = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authToken
        },
        body: JSON.stringify(configItem)
      }
      let url = `${OPS_DATA_URL}/configitems`
      fetch(url, fetchOption).then(
        res => res.json().then(result => {
          this.setState({ saving: false });
          message.success('Configurations has been saved');
          this.props.onSubmit();
        }
        ).catch((err) => {
          this.setState({ saving: false });
          message.error(err);
        }))
    }
    );
  };


  render() {
    const { getFieldDecorator } = this.props.form;
    const { saving } = this.state;
    return (
      <Box>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="Farm Name">
            {getFieldDecorator('farm', {
              initialValue: "",
              rules: [{ required: true, message: 'Please input the name of this farm!' }],
            })(<Input />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={saving}>Save</Button>
          </Form.Item>
        </Form>
      </Box>
    );
  }
}
const WrappedConfigurationsForm = Form.create()(InstanceForm);
export default WrappedConfigurationsForm;