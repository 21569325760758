import React from 'react';
import { Navbar, Nav } from 'react-bootstrap'
import { ReactComponent as Logo } from './icons/opentext-logo_inverse.svg';
import { portalPageTitle } from '../../config'

const Header = () => {
  return (
    <Navbar bg="primary" id="Navbar" variant="dark">
        <Navbar.Brand className='logo'><Logo /></Navbar.Brand>
        <Navbar.Brand>
          <div style={{borderLeft: '1px solid #FFFFFF', height: '18px'}} />
        </Navbar.Brand>
        <Nav className="me-auto">
          <Navbar.Brand>{portalPageTitle}</Navbar.Brand>
          <Navbar.Brand>
            <div style={{width: '16px'}} />
          </Navbar.Brand>
          <Navbar.Brand>Dashboard</Navbar.Brand>
        </Nav>
    </Navbar>

  )
}

export default Header;