import React, { Component } from "react";
import { Tabs } from 'antd';
import { Box } from 'grommet';
import ConfigForm from "../components/ConfigForm";
import { OPS_DATA_URL } from '../config';
const axios = require('axios');

const { TabPane } = Tabs;

class ConfigConsole extends Component {

  constructor(props) {
    super(props);
    this.state = { configItems: null }
  }

  // componentDidMount() {
  //   this._getData();
  // }

  componentDidMount() {
    this._getData();
  }


  async _getData() {
    let url = `${OPS_DATA_URL}/configitems`;
    const response = await axios.get(url);
    const configItems = response.data.sort((a, b) => {
      var nameA = a.farm.toUpperCase(); // ignore upper and lowercase
      var nameB = b.farm.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    if (Array.isArray(configItems) && configItems.length !== 0) {
      this.setState({ configItems });
    }
  }

  render() {
    const { configItems } = this.state;
    // console.log(configItems);
    if (configItems) {
      return (
        <Box width="945px" align="start">
          <Tabs tabPosition="left">
            {
              configItems.map((item, key) =>
              (
                <TabPane tab={item.farm.toUpperCase()} key={item.farm}>
                  <ConfigForm configitem={item} />
                </TabPane>
              )
              )
            }
          </Tabs>
        </Box>
      );
    } else {
      return <Box />
    }

  }
}

export default ConfigConsole;