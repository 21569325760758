import React, { Component } from 'react';
import { Box, Text } from "grommet";
import { StatusGoodSmall } from 'grommet-icons';
import styled from 'styled-components';


const LegendIcon = (props) => (<StatusGoodSmall size="15px" height="15px" color={props.color} />);

class StatusIconline extends Component {

  render() {

    const LegendText = styled(Text)`
      height: 15px;
      font-size: 60%;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.14px;
      color: #767676;
      margin: ${props => props.margin}
    `

    return (
      
      <Box direction='row' width="1102px" justify='end' style={{ marginTop: "19px"}}>
        <LegendText margin="0 5px 0 0">Data from the last 24 hours</LegendText>
        <LegendIcon color="#1aac60" />
        <LegendText margin="0 5px 0">Normal</LegendText>
        <LegendIcon color="#ffc002" />
        <LegendText margin="0 5px 0">Interrupted</LegendText>
        <LegendIcon color="#e5004c" />
        <LegendText margin="0 5px 0">Down</LegendText>
        <LegendIcon color="#bdbec0" />
        <LegendText margin={{right:"5px"}}>Maintenance</LegendText>
      </Box>
      
    )
  }
}

export default StatusIconline;
