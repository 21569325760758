import React, { Component } from 'react';
import { //Image,
  Grommet,
  Box,
  // Button,
  Text,
  Anchor
} from "grommet";
// import logo from './logo.svg';
import { brandColor } from "../config";
import { Icon } from 'antd';


const HeadTheme = {
  global: {
    colors: {
      brand: brandColor
    }
  }
};

class SystemAlert extends Component {
  render() {

    let titleStyle = {
      fontSize: "15px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "0.3px",
      color: "#ffffff",
      marginLeft: "15px"
    };

    let codeStyle = {
      fontSize: "15px",
      fontWeight: "normal",
      fontStyle: "u",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "0.3px",
      color: "#ffffff",
      marginLeft: "20px",
      textDecoration: 'underline'
    }

    // let navButton = {
    //   fontFamily: "Arial",
    //   fontSize: "16px",
    //   fontWeight: "normal",
    //   fontStyle: "normal",
    //   fontStretch: "normal",
    //   color: "#ffffff"
    // }

    return (
      // <Box direction='row' style={rootStyle}>
      //     <Image src={logo} alt="logo" style={logStyle} />
      //     <div style={verticalLineStyle}/>
      //     <Text size='medium' weight='bold' style={titleStyle}>System Health of Service Management Automation</Text>
      // </Box>
      <Grommet theme={HeadTheme}>
        <Box background="#e5004c" align="center">
          <Box
            height="37px"
            width="1102px"
            direction="row-responsive"
            justify='between'
            elevation='xsmalls'
            style={{ zIndex: '1' }}
          >
            <Box direction="row-responsive" align="center">
              <Icon type="close-circle" />
              <Text style={titleStyle}>{this.props.subject}</Text>
              <Anchor href="#"  style={codeStyle} label="click here for more information" />
            </Box>
            {/* <Box direction="row-responsive" align="center">
              <Button style={navButton}
                label="Free Trials"
                color="#3ddcca"
                primary={true}
              />
            </Box> */}
          </Box>
        </Box>
      </Grommet>
    );
  }
}

export default SystemAlert;
