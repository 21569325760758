import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import FarmCard from './FarmCard'
import SortBar from './SortBar';
import { useState } from 'react';
import './App.css'
import { farmDetails as Farms } from '../../config';


const FarmCards = () => {
    const [value, setValue] = useState("All");

    function select(item) {
        if (value === "All") return item
        return item.location === value
    }
    const SelectedFarm = Farms.filter(select);

    return (
        <>
            <SortBar value={value} setValue={setValue} />
            <Container style={{ padding: '8px 0 66px' }}>
                <Col >
                    <Row md={'auto'} sm={'auto'}>
                        {SelectedFarm.map((farm) => (
                            <Col key={farm.name}>
                                <Container key={farm.name} style={{ width: '248px', margin: "8px" }}>
                                    <FarmCard farm={farm} key={farm.name} />
                                </Container>
                            </Col>
                        ))}
                    </Row>
                </Col>


            </Container>
        </>
    )
}

export default FarmCards;