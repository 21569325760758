import React, { Component } from 'react';
import {
  Box,
  Text,
  Anchor
} from "grommet";
import styled from 'styled-components';
import { FormUp } from 'grommet-icons';

const CopyrightText = styled(Text)`
  width: 199px;
  height: 19px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.18px;
  color: #ffffff;
`

class FootBar extends Component {
  render() {
    return (
      <Box>
        {this.props.back2top ?
          <Box height="56px" align="end" background="#FFFFFF">
            <Anchor icon={<FormUp color='#000000'/>} label="Top" href="#top" style={{ marginRight: '43px', color: "#000000 !important" }} />
          </Box> : null
        }
        <Box background="#666666" align="center" height="54px" justify="center">
          <CopyrightText>&copy; Copyright {new Date().getFullYear()} OpenText</CopyrightText>
        </Box>
      </Box>
    );
  }
}

export default FootBar;
