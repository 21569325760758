import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'
import 'antd/dist/antd.css';
import '../index.css'
import CustomizedForm from '../CustomForm'
import { OPS_DATA_URL } from '../../config';
import { getNowTimestamp } from '../../utils/CommonUtil';
import { getAuthEmailFromToken, authenticateToken } from '../../utils/auth';

class MaintenceDataModalPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fields: {},
      loading: false
    }
  }

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    title: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  handleFormChange = changedFields => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields },
    }));
  };

  loadingChangeState(status) {
    this.setState({
      loading: status
    })
  }

  submitEditClick = (e) => {
    let authToken = authenticateToken();
    e.preventDefault()
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        this.loadingChangeState(true)
        let id = this.props.fields.id
        let operator = getAuthEmailFromToken();
        const obj = {
          id: id,
          farm: values['farm'],
          subject: values['subject'],
          start_time: getNowTimestamp(values['start_time']),
          end_time: getNowTimestamp(values['end_time']),
          desc: values['desc'],
          ci_status: values['ci_status'],
          operator: operator
        };
        var fetchOption = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': authToken
          },
          body: JSON.stringify(obj)
        }
        let url = `${OPS_DATA_URL}`
        if (this.props.type === 'Maintenance') {
          url = url + '/maintenances'
        } else {
          url = url + '/incidents'
        }
        fetch(url, fetchOption).then(
          res => res.json().then(result => {
            this.loadingChangeState(false)
            this.props.onSubmit(true)
          }
          ).catch((e) => {
            this.loadingChangeState(false)
            this.props.onSubmit(false)
          }))
      }
    })

  }

  submitClick = (e) => {
    let authToken = authenticateToken();
    const { form } = this.formRef.props;
    e.preventDefault()

    form.validateFields((err, values) => {
      if (!!err) {
        return;
      } else {
        this.loadingChangeState(true)
        let operator = getAuthEmailFromToken();
        const obj = {
          farm: values['farm'],
          subject: values['subject'],
          start_time: getNowTimestamp(values['start_time']),
          end_time: getNowTimestamp(values['end_time']),
          desc: values['desc'],
          ci_status: values['ci_status'],
          operator: operator
        };
        var fetchOption = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': authToken
          },
          body: JSON.stringify(obj)
        }
        let url = `${OPS_DATA_URL}`
        if (this.props.type === 'Maintenance') {
          url = url + '/maintenances'
        } else {
          url = url + '/incidents'
        }
        fetch(url, fetchOption).then(
          res => {
            if (res.ok) {
              res.json().then(result => {
                this.setState({
                  data: result,
                  loading: false
                })
                this.loadingChangeState(false)
                this.props.onSubmit(true)
              })
            } else {
              this.loadingChangeState(false)
              this.props.onSubmit(false)
            }
          }
        )
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isEdit) {
      this.setState({
        fields: nextProps.fields
      })
    } else {
      this.setState({
        fields: {
          'start_time': getNowTimestamp(),
          'end_time': getNowTimestamp(),
          'subject': '',
          'desc': ''
        }
      })
    }
  }

  onCancel = (e) => {
    e.preventDefault()
    this.formRef.props.form.resetFields()
    this.setState({
      fields: {}
    })
    this.props.onCancel()
  }

  saveFormRef = formRef => {
    this.formRef = formRef
  }

  render() {
    const { fields } = this.state;
    const { title, visible } = this.props;

    return (
      <Modal
        visible={visible}
        onCancel={this.onCancel}
        id="deploy-tenant-modal-container"
        title={title}
        width='60%'
        destroyOnClose
        footer={[
          <Button key="back" onClick={this.onCancel}>
            Cancel
            </Button>,
          <Button key="submit" type="primary" loading={this.state.loading} onClick={this.props.isEdit ? this.submitEditClick : this.submitClick}>
            Submit
            </Button>,
        ]}
      >
        <CustomizedForm  {...fields} type={this.props.type} onChange={this.handleFormChange} wrappedComponentRef={this.saveFormRef} isEdit={this.props.isEdit} />
      </Modal>
    );
  };
}

export default MaintenceDataModalPage
