import React, { Component } from "react";
import {
  Box,
  Tabs, Tab,
  Text,
  Grommet
} from 'grommet';
import Incident from './Incident';
import Maintenance from './Maintenance';
import ConfigConsole from './ConfigConsole';
import SLAConfig from "./SLAConfig";
import HeadBar from '../views/HeadBar';
import InstanceForm from "../components/InstanceForm";
import { Modal, Button } from 'antd';
import { withAuthenticator } from '@aws-amplify/ui-react';

const RichTabTitle = ({ label, active }) => {
  return (
    <Box direction="row" align="center" gap="small" margin="small" >
      <Text size="16px">
        {active ? (<strong>{label}</strong>) : label}
      </Text>
    </Box>
  )
};

const customTheme = {
  global: {
    colors: {
      focus: "none"
    },
  },
  tab: {
    border: {
      color: "transparent",
      size: "3px"
    },
    active: {
      color: "dark-1"
    },
    pad: {
      horizontal: "small"
    }
  },
  tabs: {
    header: {
      background: "#f6f6f6",
    },
  }
};


class OpsMan extends Component {

  constructor(props) {
    super(props);
    this.state = { index: 0 }
  }

  onActive = index => this.setState({ index });

  showInstanceModal = () => {
    this.setState({
      instanceModalVisible: true,
    });
  };

  handleInstanceModalCancel = e => {
    console.log(e);
    this.setState({
      instanceModalVisible: false,
    });
    window.location.reload();
  };

  render() {
    const { index } = this.state;

    return (

      <Grommet theme={customTheme}>
        <HeadBar />
        <Tabs activeIndex={index} onActive={this.onActive}>
          <Tab title={
            <RichTabTitle
              label="CONFIGURATIONS" active={index === 0}
            />
          }>
            <Box
              margin="small"
              pad="xxlarge"
              align="center"
            //background="accent-1"
            >
              <Button type="primary" onClick={this.showInstanceModal} >Create new farm</Button>
              <ConfigConsole />
              <Modal
                title="Create new farm"
                visible={this.state.instanceModalVisible}
                onCancel={this.handleInstanceModalCancel}
                footer={null}
              >
                <InstanceForm onSubmit={this.handleInstanceModalCancel} />
              </Modal>
            </Box>
          </Tab>
          <Tab title={
            <RichTabTitle
              label="INCIDENT REPORT" active={index === 1}
            />
          }>
            <Box
              margin="small"
              pad="xxlarge"
              align="center"
            //background="accent-2"
            >
              <Incident />
            </Box>
          </Tab>
          <Tab title={
            <RichTabTitle
              label="PLANNED MAINTENCE SCHEDULE" active={index === 2}
            />
          }>
            <Box
              margin="small"
              pad="xxlarge"
              align="center"
            //background="accent-1"
            >
              <Maintenance />
            </Box>
          </Tab>
          <Tab title={
            <RichTabTitle
              label="SLA CONFIGURATOR" active={index === 3}
            />
          }>
            <Box
              margin="small"
              pad="xxlarge"
              align="center"
            //background="accent-1"
            >
              <SLAConfig />
            </Box>
          </Tab>
        </Tabs>
        {/* <FootBar/> */}
      </Grommet>

    );
  }
}

export default withAuthenticator(OpsMan);