// ESM Config
const SMAX_ARTICLES = { code: "SMAX_ARTICLES", name: "smax articles" };
const SMAX_NEW_REQUEST = { code: "SMAX_NEW_REQUEST", name: "smax create new request" };
const SMAX_ESS_ARTICLE = { code: "SMAX_ESS_ARTICLE", name: "smax ess article" };
const SMAX_ESS_OFFERING = { code: "SMAX_ESS_OFFERING", name: "smax ess offering" };
const SMAX_LIVE_SUPPORT = { code: "SMAX_LIVE_SUPPORT", name: "smax live support" };
const SMAX_LOGIN = { code: "SMAX_LOGIN", name: "smax login" };
const SMAX_OPEN_EDIT_REQUEST = { code: "SMAX_OPEN_EDIT_REQUEST", name: "smax open and edit the request" };
const SMAX_SEARCH = { code: "SMAX_SEARCH", name: "smax search" };
const SMAX_SACM = { code: "SMAX_SACM", name: "smax sacm" };
const CMS_INVENTORY = { code: "CMS_INVENTORY", name: "CMS Inventory" };
const CMS_SEARCH_VIEW_REPORT = { code: "CMS_SEARCH_VIEW_REPORT", name: "CMS Search View and Report" };
const CMS_UI_LOGIN = { code: "CMS_UI_LOGIN", name: "CMS UI Login" };
const CMS_VIEW_MAP = { code: "CMS_VIEW_MAP", name: "CMS View map" };

export const serviceCollection = [
  [
    SMAX_ARTICLES,
    SMAX_NEW_REQUEST,
    SMAX_ESS_ARTICLE,
    SMAX_ESS_OFFERING,
    SMAX_LIVE_SUPPORT,
    SMAX_LOGIN,
    SMAX_OPEN_EDIT_REQUEST,
    SMAX_SEARCH,
    SMAX_SACM,
    CMS_INVENTORY,
    CMS_SEARCH_VIEW_REPORT,
    CMS_UI_LOGIN,
    CMS_VIEW_MAP
  ],
  [
    SMAX_ARTICLES,
    SMAX_NEW_REQUEST,
    SMAX_ESS_ARTICLE,
    SMAX_ESS_OFFERING,
    SMAX_LIVE_SUPPORT,
    SMAX_LOGIN,
    SMAX_OPEN_EDIT_REQUEST,
    SMAX_SEARCH,
    SMAX_SACM,
  ]
];

export const APPMODE = "ESM";
export const FARM_ALIAS = ['us2', 'us24', 'us26', 'us6', 'us7', 'eu3', 'eu8', 'eu18', 'eu28', 'ap10', 'jp12', 'br14', 'ca16'];
export const MODE = ['ESM', 'SMAX', 'HCMX', 'AMX'];
export const UNITLABEL = "farm";

export const userPoolId = "us-west-2_hdQ8PQGg6";
export const userPoolWebClientId = "61cb6mn9eulgfmg35c7g6g9u6k";
export const region = "us-west-2";
export const mainPageTitle = "Enterprise Service Management System Health";
export const portalPageTitle = "System Health of Service Management Automation";

export const farmGeoData = {
  "features": [
    {
      "type": "Feature",
      "properties": {
        "title": "eu8",
        "description": "Frankfurt farm"
      },
      "geometry": {
        "coordinates": [8.34, 50],
        "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "eu18",
        "description": "Frankfurt farm"
      },
      "geometry": {
        "coordinates": [8.34, 50],
        "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "eu28",
        "description": "Frankfurt farm"
      },
      "geometry": {
        "coordinates": [8.34, 50],
        "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "eu3",
        "description": "Frankfurt farm"
      },
      "geometry": {
        "coordinates": [8.34, 50],
        "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "us2",
        "description": "Oregon farm"
      },
      "geometry": {
        "coordinates": [-122.39, 45.31],
        "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "us24",
        "description": "Oregon farm"
      },
      "geometry": {
        "coordinates": [-122.39, 45.31],
        "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "us26",
        "description": "Oregon farm"
      },
      "geometry": {
        "coordinates": [-122.39, 45.31],
        "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "us6",
        "description": "Oregon farm"
      },
      "geometry": {
        "coordinates": [-122.39, 45.31],
        "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "us7",
        "description": "Oregon farm"
      },
      "geometry": {
        "coordinates": [-122.39, 45.31],
        "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "jp12",
        "description": "Tokyo farm"
      },
      "geometry": {
        "coordinates": [139.44, 35.41],
        "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "ap10",
        "description": "Sydney farm"
      },
      "geometry": {
        "coordinates": [151.12, -33.41],
        "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "br14",
        "description": "São Paulo farm"
      },
      "geometry": {
        "coordinates": [-46.6334, -23.5507],
        "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "ca16",
        "description": "Central farm"
      },
      "geometry": {
        "coordinates": [-79, 50],
        "type": "Point"
      }
    },
  ],
  "type": "FeatureCollection"
}

export const farmDetails = [
  {
    "name": "us2",
    "location": "Oregon",
    "version": "2022.1",
    "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=us2"
  },
  {
    "name": "us24",
    "location": "Oregon",
    "version": "2022.1",
    "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=us24"
  },
  {
    "name": "us26",
    "location": "Oregon",
    "version": "2022.1",
    "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=us26"
  },
  {
    "name": "us6",
    "location": "Oregon",
    "version": "2022.1",
    "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=us6"
  },
  {
    "name": "us7",
    "location": "Oregon",
    "version": "2022.1",
    "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=us7"
  },
  {
    "name": "eu3",
    "location": "Frankfurt",
    "version": "2022.1",
    "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=eu3"
  },
  {
    "name": "eu8",
    "location": "Frankfurt",
    "version": "2022.1",
    "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=eu8"
  },
  {
    "name": "eu18",
    "location": "Frankfurt",
    "version": "2022.1",
    "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=eu18"
  },
  {
    "name": "eu28",
    "location": "Frankfurt",
    "version": "2022.1",
    "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=eu28"
  },
  {
    "name": "ap10",
    "location": "Sydney",
    "version": "2022.1",
    "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=ap10"
  },
  {
    "name": "jp12",
    "location": "Tokyo",
    "version": "2022.1",
    "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=jp12"
  },
  {
    "name": "br14",
    "location": "São Paulo",
    "version": "2022.1",
    "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=br14"
  },
  {
    "name": "ca16",
    "location": "Central",
    "version": "2022.1",
    "url": "https://smax-health.saas.microfocus.com/dashboard/?farm=ca16"
  }
]


// dev url
export const OPS_DATA_URL = "https://kjncjstlqa.execute-api.us-west-2.amazonaws.com/dev";
export const MONITOR_DATA_URL = "https://adubp7zqfa.execute-api.us-west-2.amazonaws.com/dev";
// global config
export const okColor = "#1aac60";
export const warnColor = "#ffc002";
export const errorColor = "#e5004c";
export const nodataColor = "#bdbec0";
export const MAINTENANCE_STATUS = [{ label: 'NOT STARTED', color: nodataColor }, { label: 'IN PROGRESS', color: warnColor }, { label: 'COMPLETED', color: okColor }];
export const INCIDENT_STATUS = [{ label: 'UNRESOLVED', color: warnColor }, { label: 'RESOLVED', color: okColor }];
export const brandColor = "linear-gradient(270deg, #088CB2 0%, #146693 8.78%, #23346A 36.9%, #222E61 74.96%, #19224A 100%)";
export const DATE_PICKER_FORMAT = "YYYY-MM-DD HH:mm:ss zZ";

