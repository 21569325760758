import React, { useRef, useEffect } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import './App.css';
import ReactDOM from 'react-dom';
import Popup from './Popup';
import { farmGeoData as geodata } from "../../config";

mapboxgl.accessToken = 'pk.eyJ1Ijoid2VuaGFvbWluIiwiYSI6ImNsMDk2YWQ2NTBhMjIzY3F1MWZkNThobnoifQ.I-zd79TrdEyE8jlBt5x2uw';

export default function Map() {

  const mapContainer = useRef(null);

  const popUpRef = useRef(new mapboxgl.Popup({ offset: 15 }));


  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      // See style options here: https://docs.mapbox.com/api/maps/#styles
      style: 'mapbox://styles/mapbox/light-v10',
      center: [-104.9876, 39.7405],
      zoom: 1.5,
    });
    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    map.on("load", () => {
      // this time we are hitting an ESRI API that returns
      // data in the geojson format
      // see https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#geojson
      map.addSource("bus-routes", {
        type: "geojson",
        data:
          geodata,
      })
      // see https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#line

      // Add a circle layer
      map.addLayer({
        'id': 'farm-location',
        'type': 'circle',
        'source': 'bus-routes',
        'paint': {
          'circle-color': '#4264fb',
          'circle-radius': 6,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#ffffff'
        }
      });
    })
    map.on("click", e => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["farm-location"],
      })
      if (features.length > 0) {

        const feature = features[0];
        const popupNode = document.createElement('div');
        ReactDOM.render(<Popup features={features} />, popupNode);
        // set popup on map
        popUpRef.current.setLngLat(feature.geometry.coordinates).setDOMContent(popupNode).addTo(map);
      }
    })


  });


  return (
    <div>
      <div ref={mapContainer} className="map-container" />

    </div>
  );



}