import React,{ Component } from 'react'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import styled from 'styled-components';

const MUIDataTableStyled = styled(MUIDataTable)`
  && {
    [role=toolbar]{
      display: flex;
      justify-content: space-around;
    }
    [role=toolbar] h6{
      display:flex;
      align-content:start;
    }
    button{
      margin-left:100px;
    }
     
    font-size: 40px;
    font-family:'Metric New'
    td {
      font-size: 16px;
      fontFamily:'Metric New'
    }
    .MuiTypography-h6 {
      font-size:16px;
      font-family:"Metric New";
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: 0.0075em;
      color: #333333;
      font-weight: bold;
    }
    .MuiTypography-subtitle1 {
      font-size:16px;
      font-family:"Metric New";
      font-weight: 400;
      line-height: 1.75;
      letter-spacing: 0.00938em
    }
    .MuiTableCell-head{
      font-family:"Metric New";
    }
    .MuiTableCell-body{
      font-family:"Metric New";
    }

  }
`;

export default class CustomMenuTable extends Component {

    constructor(props){
        super(props)
        this.state={
            options :{
                print:false,
                download:false,
                viewColumns:false,
                selectableRows: "none",
                filter:false,
                pagination:false,
                responsive:'scroll',
                fixedHeader:false,
                onRowClick:(rowData,rowMeta)=>{
                    this.chooseSelectRowData(rowData[1], rowData[2])
                }
              }
        }
    }

    chooseSelectRowData=(indexData, farmCode)=>{
        this.props.changeSelectValue(indexData, farmCode)
    }
    
    

    getMuiTheme = () => createTheme({
        overrides: {
            MUIDataTable:{
                responsiveScroll: {
                    overflowX:'auto',
                    overflow:'auto',
                    maxHeight: '230px',
                    height:'230px'

                },
                paper: {
                    boxShadow: "none",
                    width:'361px',
                  }
            }
        },
      });

    render() {
    const {data,columns} = this.props;
    const {options} = this.state;
      return (
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTableStyled title="Search" data={data} columns={columns} options={options} />
          
        </MuiThemeProvider>
      );
    }
  } 



