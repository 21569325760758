import React, { Component } from 'react';
import { Box, Text } from "grommet";
// import '@micro-focus/quantum-ux-bootstrap/dist/common/css/qtm-busy.css';
import { Grommet, Table, TableBody, TableCell, TableHeader, TableRow, Button } from 'grommet';
// import {ErrorOutline} from '@material-ui/icons';
// import { Tooltip } from 'antd';
import { getCurrentStatus } from "../utils/DBUtils";
import { getLocalHour, getLocalHourText, isMainHour } from "../utils/CommonUtil";
import StatusIcon from "./StatusIcon";
import StatusIconMobil from "./StatusIconMobil";
import { serviceCollection, brandColor, MONITOR_DATA_URL, FARM_ALIAS } from "../config";
import styled from 'styled-components';
import { FormUp, FormPrevious, FormNext } from 'grommet-icons';
import { getNewDateWidth, getToDay, getYesterDay, getPreviousTimestamp, getNextTimestamp } from "../utils/CommonUtil";
import loader from '../icons/loader_animated_Light.gif';

const customTheme = {
  // global: {
  //   font: {
  //     family: "Helvetica"
  //   }
  // },
  table: {

    // tableLayout: 'fixed',
    body: {
      align: "center",
      //pad: { horizontal: "small", vertical: "small" },
      verticalAlign: "middle",
      border: undefined,

    },
    footer: {
      align: "center",
      border: undefined,
      //pad: { horizontal: "small", vertical: "small" },
      verticalAlign: "bottom"
    },
    header:
    {

      height: "26px",
      align: "center",
      border: undefined,
      fill: "horizontal",
      //pad: { horizontal: "small", vertical: "small" },
      verticalAlign: "bottom",
      background: {
        color: brandColor,
        opacity: "strong"
      }
    }
  }
};

const CommonCell = styled(TableCell)`
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
`

const CommonTd = styled.td`
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
`

const TimeHeadCell = styled(CommonTd)`
  width: 38px;
`

const RangeHeadCell = styled(CommonTd)`
  width: 26px;
`

const TailHeadCell = styled(CommonTd)`
  width: 26px;
`

const BodyCell = styled(CommonCell)`
  background-color: ${props => props.rowCount % 2 === 1 ? "#f4f9ff" : "#ffffff"};
  height: 41px;
`

const TableTitleText = styled(Text)`
  height: 26px;
  line-height: 26px;
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.13px;
  color: #f6f6f6;
  text-transform: uppercase;
  text-indent: 22px;
`
const TableTitleTextMobil = styled(Text)`
  height: 26px;
  line-height: 26px;
  font-family: Arial;
  font-size: 8px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.13px;
  color: #f6f6f6;
  text-transform: uppercase;
  text-indent: 11px;
`

const TimeText = styled(Text)`
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.13px;
  color: #f6f6f6;
  text-align: center;
`
const TimeTextMobil = styled(Text)`
  font-family: Arial;
  font-size: 8px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.13px;
  color: #f6f6f6;
  text-align: center;
`

const ServiceNameText = styled(Text)`
  font-size: 14px;
  font-weight: bolder;
  font-style: normal;
  font-stretch: normal;
  line-height: 41px;
  letter-spacing: 0.19px;
  color: #333333;
  text-transform: uppercase;
  text-indent: 10px;
`
const ServiceNameTextMobil = styled(Text)`
  font-size: 8px;
  font-weight: bolder;
  font-style: normal;
  font-stretch: normal;
  line-height: 41px;
  letter-spacing: 0.19px;
  color: #333333;
  text-transform: uppercase;
  text-indent: 5px;
`

const TimeRangeText = styled(Text)`
  height: 38px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 38px;
  letter-spacing: 0.16px;
  color: #767676;
`

const CurrentPointText = styled(Text)`
  height: 12px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 12px;
  letter-spacing: 0.16px;
  color: #767676;
`
const getOfficalServiceName = (serviceKey) => {
  // if (serviceKey === "SACM") {
  //   return "Native SACM";
  // } else {
  return serviceKey;
  // }
}

class StatusContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStatus: null,
      allDayStatus: null,
      URL: `${MONITOR_DATA_URL}/status/${FARM_ALIAS[props.farm]}`,
      currentTime: null,
      prevHours: null,
      service: serviceCollection[props.mode]
    }
  }

  componentDidMount() {
    this._getData();
    //(1-e/10)*5*60*1000=218452
    setInterval(() => this._getData(), 218452);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log("componentDidUpdate this.props.URL"+ this.state.URL);
    // console.log("componentDidUpdate prevProps.URL"+ prevState.URL);
    // Typical usage (don't forget to compare props):
    if (this.state.URL !== prevState.URL) {
      // this.setState({URL: `${MONITOR_DATA_URL}/${FARM_ALIAS[this.props.farm]}`});
      this._getData();
    }
  }

  async _getData() {
    let prevHours = 24;
    let currentTime = Date.now();
    let URL = `${this.state.URL}/${prevHours}`;
    let allDayStatus = [];
    let service = this.state.service;
    // console.log("fetch data from "+ this.state.URL);
    await getCurrentStatus(URL, currentTime, service).then((res) => {
      allDayStatus = res;
    });
    this.setState({
      currentStatus: allDayStatus[allDayStatus.length - 1],
      allDayStatus: allDayStatus,
      currentTime,
      prevHours
    })
  }

  async goYesterday() {
    let currentStatus = this.state.currentStatus;
    let prevHours = this.state.prevHours + 24;
    let currentTime = getPreviousTimestamp(this.state.currentTime, 1);
    let URL = `${this.state.URL}/${prevHours}`;
    let allDayStatus = [];
    let service = this.state.service;
    // console.log("fetch data from "+ this.state.URL);
    await getCurrentStatus(URL, currentTime, service).then((res) => {
      allDayStatus = res;
    });
    this.setState({
      currentStatus, allDayStatus, currentTime, prevHours
    })
  }

  async goNextDay() {
    let currentStatus = this.state.currentStatus;
    let prevHours = this.state.prevHours - 24;
    let currentTime = getNextTimestamp(this.state.currentTime, 1);
    let URL = `${this.state.URL}/${prevHours}`;
    let allDayStatus = [];
    let service = this.state.service;
    // console.log("fetch data from "+ this.state.URL);
    await getCurrentStatus(URL, currentTime, service).then((res) => {
      allDayStatus = res;
    });
    this.setState({
      currentStatus, allDayStatus, currentTime, prevHours
    })
  }

  render() {
    const { currentStatus, allDayStatus, currentTime, prevHours, service } = this.state;
    if (!allDayStatus || !currentStatus) {
      return (
        <Box style={{ margin: '156px auto auto auto', width: '1102', height: '313px' }}>
           <img src={loader} alt='loading' />
        </Box>
      )
    }

    const widthToday = getNewDateWidth(currentTime);
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      return (<Grommet theme={customTheme}>

        <Box justify="end" width="1102px" align="center" direction='row' style={{ marginTop: '2px' }}>
          <Box justify="start" width="316px"></Box>
          <Box justify="end" width="820px" direction="row" >
            <Box gridArea='oldday' align="center" width={`${819 - widthToday}px`}><TimeRangeText>{getYesterDay(currentTime)}</TimeRangeText></Box>
            <Box gridArea='separator' align="center" width="1px"><TimeRangeText>┊</TimeRangeText></Box>
            <Box gridArea='newday' align="center" width={`${widthToday}px`}><TimeRangeText>{getToDay(currentTime)}</TimeRangeText></Box>
          </Box>
        </Box>
        <Box width="" direction='row-responsive'>
          <Table resizeable="true" >
            <TableHeader style={{ background: brandColor }}>
              <TableRow>
                <CommonCell align="start">
                  <Box justify="between" direction="row" width="126px" >
                    <TableTitleTextMobil>Service in Monitor</TableTitleTextMobil>
                    {prevHours < 72 ?
                      <Button plain icon={<FormPrevious color="#ffffff" />} onClick={this.goYesterday.bind(this)} /> : null
                    }
                  </Box>
                </CommonCell>
                {allDayStatus.map((item, key) =>
                (
                  isMainHour(getLocalHour(item.Code.substring(8, 10))) ?
                    <TimeHeadCell key={`head${key}`}>
                      <TimeTextMobil>{`${getLocalHourText(item.Code.substring(8, 10))}`}</TimeTextMobil>
                    </TimeHeadCell>
                    :
                    <RangeHeadCell key={`blank${key}`}></RangeHeadCell>
                )
                )}
                <TailHeadCell>
                  {prevHours > 24 ?
                    <Box justify="between" direction="row" width="24px">
                      <Button plain icon={<FormNext color="#ffffff" />} onClick={this.goNextDay.bind(this)} />
                    </Box> :
                    <Box justify="between" direction="row" width="24px">

                    </Box>
                  }
                </TailHeadCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {service.map((serviceName, serviceKey) => (
                <TableRow key={serviceKey}>
                  <BodyCell key={`service${serviceKey}`} align="start" rowCount={serviceKey}>
                    <Box direction="row" style={{ marginLeft: "10px" }}>
                      {/* <StatusIcon status={currentStatus[`${serviceName.name}`]} time={currentStatus.Code.substring(8, 10)} /> */}
                      <ServiceNameTextMobil>{getOfficalServiceName(serviceName.name)}</ServiceNameTextMobil>
                    </Box>
                  </BodyCell>
                  {allDayStatus.map((statusItem, itemKey) => (
                    <BodyCell key={`serviceName${serviceKey}${itemKey}`} rowCount={serviceKey} align="center">
                      <StatusIconMobil status={statusItem[`${serviceName.code}`]} time={statusItem.Code.substring(8, 10)} />
                    </BodyCell>
                  ))}
                  <BodyCell key={`blank${serviceKey}`} rowCount={serviceKey}></BodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </Box>
      </Grommet>)
    }

    return (
      <Grommet theme={customTheme}>
        <Box justify="end" width="1102px" align="center" direction='row' style={{ marginTop: '2px' }}>
          <Box justify="start" width="316px"></Box>
          <Box justify="end" width="820px" direction="row" >
            <Box gridArea='oldday' align="center" width={`${819 - widthToday}px`}><TimeRangeText>{getYesterDay(currentTime)}</TimeRangeText></Box>
            <Box gridArea='separator' align="center" width="1px"><TimeRangeText>┊</TimeRangeText></Box>
            <Box gridArea='newday' align="center" width={`${widthToday}px`}><TimeRangeText>{getToDay(currentTime)}</TimeRangeText></Box>
          </Box>
        </Box>

        <Box width="" direction='row-responsive'>
          <Table resizeable="true" >
            <TableHeader style={{ background: brandColor }}>
              <TableRow>
                <CommonCell align="start">
                  <Box justify="between" direction="row-responsive" width="316px" >
                    <TableTitleText>Service in Monitor</TableTitleText>
                    {prevHours < 72 ?
                      <Button plain icon={<FormPrevious color="#ffffff" />} onClick={this.goYesterday.bind(this)} /> : null
                    }
                  </Box>
                </CommonCell>
                {allDayStatus.map((item, key) =>
                (
                  isMainHour(getLocalHour(item.Code.substring(8, 10))) ?
                    <TimeHeadCell key={`head${key}`}>
                      <TimeText>{`${getLocalHourText(item.Code.substring(8, 10))} : 00`}</TimeText>
                    </TimeHeadCell>
                    :
                    <RangeHeadCell key={`blank${key}`}></RangeHeadCell>
                )
                )}
                <TailHeadCell>
                  {prevHours > 24 ?
                    <Box justify="between" direction="row-responsive" width="24px">
                      <Button plain icon={<FormNext color="#ffffff" />} onClick={this.goNextDay.bind(this)} />
                    </Box> : null
                  }
                </TailHeadCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {service.map((serviceName, serviceKey) => (
                <TableRow key={serviceKey}>
                  <BodyCell key={`service${serviceKey}`} align="start" rowCount={serviceKey}>
                    <Box direction="row" style={{ marginLeft: "20px" }}>
                      <StatusIcon status={currentStatus[`${serviceName.code}`]} time={currentStatus.Code.substring(8, 10)} />
                      <ServiceNameText>{getOfficalServiceName(serviceName.name)}</ServiceNameText>
                    </Box>
                  </BodyCell>
                  {allDayStatus.map((statusItem, itemKey) => (
                    <BodyCell key={`serviceName${serviceKey}${itemKey}`} rowCount={serviceKey} align="center">
                      <StatusIcon status={statusItem[`${serviceName.code}`]} time={statusItem.Code.substring(8, 10)} />
                    </BodyCell>
                  ))}
                  <BodyCell key={`blank${serviceKey}`} rowCount={serviceKey}></BodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </Box>
        <Box justify="end" width="" align="center" direction='row'>
          {prevHours === 24 ?
            <Box width="90px" align="center">
              <FormUp color="black" size="16px" /><CurrentPointText>Current</CurrentPointText>
            </Box>
            : <Box height="28px" align="center"></Box>
          }
        </Box>
      </Grommet>

    )
  }
}

export default StatusContent;
