import React from 'react';


const Popup = (props) => {
  const length = props.features.length;
  if (length > 0) {
    const listLinks = props.features.map((feature) =>
      <a href={`/dashboard/?farm=${feature.properties.title}`} style={{ marginRight: "10px" }} key={feature.properties.title}>
        {feature.properties.title.toUpperCase()}</a>
    );
    const { description } = props.features[0].properties;
    return (
      <div className='popup'>
        <span>{description}</span> <br />
        {listLinks}
      </div>
    );
  }
};

export default Popup;