import React, { Component } from 'react';
import { Box } from "grommet";
import { Link } from 'react-router-dom';

class NotFound extends Component{
    render(){
        return(
            <Box style={{margin:'auto',width:'80%'}}>
                <h1 style={{margin:'20px auto auto auto'}}>Page Not Found</h1>
                <Link to="/dashboard" style={{margin:'20px auto auto 0',color:'#00baff',fontSize:'20px'}}>Service health report</Link>
                <Link to="/longtermreport" style={{margin:'20px auto auto 0',color:'#00baff',fontSize:'20px'}}>SLA report</Link>
            </Box>
        )
    }
}

export default NotFound;