import moment from "moment/moment";
// import { service } from "../config";
const axios = require('axios');
const dateOptions = { year: 'numeric', month: 'short', day: 'numeric', hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' };
const MonthYearOptions = { year: 'numeric', month: 'short' }
const MonthOptions = { month: 'short' }
const YearOptions = { year: 'numeric' }
const dateLocal = "en-US"
dateOptions.timeZone = 'UTC';
MonthYearOptions.timeZone = 'UTC';
MonthOptions.timeZone = 'UTC';
YearOptions.timeZone = 'UTC';

const getFormattedStringByOption = (dateString, option) => {
  let firstDate = new Date(dateString);
  return firstDate.toLocaleString(dateLocal, option);
}

const getFormattedSLA = (vdata) => {
  let data = Number.parseFloat(vdata);
  let sla = (Math.round(data * 100000) / 1000);
  return sla + '%';
}

// function dump_obj(myObject) {
//   var s = "";
//   for (var property in myObject) {
//     s = s + "\n " + property + ": " + myObject[property];
//   }
//   console.log(s);
// }

export async function getSLAHistory(url) {
  let SLAData = [];
  await getDatafrom(url).then((res) => {
    // dump_obj(res);
    res.forEach((item) => {
      let dateString = item.code;
      let sla = getFormattedSLA(item.availability);
      let month = getFormattedStringByOption(dateString, MonthOptions);
      let year = getFormattedStringByOption(dateString, YearOptions);
      let SLAItem = [sla, month, year];
      SLAData.push(SLAItem);
      return 0;
    })

  });
  return SLAData;
}

export async function getSysAlert(url) {
  let valert = null;
  await getDatafrom(url).then((res) => {
    if (res && res.subject && res.subject.trim().length > 0) {
      valert = res;
    }
  });
  return valert;
}

export async function getCurrentSLA(url) {
  let SLAData = [];
  await getDatafrom(url).then((res) => {
    SLAData = res
  });
  if (SLAData && SLAData.length === 1) {
    let dateString = SLAData[0].code;
    let sla = SLAData[0].availability;
    let startDate = getFormattedStringByOption(dateString, MonthYearOptions);
    // let lastDay = new Date(firstDate.getFullYear(), firstDate.getMonth() + 1, 0).toLocaleString(dateLocal, SLADateOptions);

    return {
      SLACount: getFormattedSLA(sla),
      SLAPeriod: `SLA (${startDate})`
    };
  } else {
    return {
      SLACount: 'No recent data',
      SLAPeriod: 'SLA'
    }
  }
}

export async function getDatafrom(url) {
  try {
    const response = await axios.get(url);
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
  // return [
  //   { 'Farm': { S: 'Internal production' }, 'Code': { S: '2019042821' }, 'Articles': { S: 'ok' }, 'Create new Request': { S: 'ok' }, 'ESS Article': { S: 'ok' }, 'ESS Offering': { S: 'ok' }, 'Live Support': { S: 'ok' }, 'Login': { S: 'ok' }, 'Open and Edit the request': { S: 'ok' }, 'Search': { S: 'ok' } },
  // ];
}

// //Get the hourly status in the past 24/72 hours
export async function getCurrentStatus(URL, currentTimestamp, service) {
  // Get current datetime
  const currentDate = new Date(currentTimestamp);
  //List the last 24/72 hours
  let last24hours = [];
  for (let i = 24; i >= 0; i--) {
    let hour = moment.utc(currentDate).subtract(i, 'hour').format('YYYYMMDDHH');
    // console.log('hour', hour);
    last24hours.push(hour);
  }

  //List the exist hours in db
  //let existHours = [];
  //List the exist hours status in db
  let freshHourStatus = [];

  await getDatafrom(URL).then((res) => {
    let existHourStatus = res; // get exists status
    last24hours.map((hour, index) => {
      //existHours.push(item.Code.N);
      //return 0;
      //let hour = moment().utc(`${currentDate}`, 'YYYYMMDDHH').subtract(index, 'h').format('YYYYMMDDHH').toString();
      // console.log(hour);
      var foundIndex = existHourStatus.findIndex((ele) => {
        return ele.Code === hour;
      })
      // console.log("foundIndex=" + foundIndex);
      if (foundIndex > -1) {
        freshHourStatus.push(existHourStatus[foundIndex]);
      } else {
        let newItem = {
          "Code": `${hour}`,
          "Farm": 'internal-prod'
        };
        for (let i in service) {
          newItem[`${service[i].code}`] = "nodata";
        }
        freshHourStatus.push(newItem);
      }
      return 0;
    });
  });
  // console.log(freshHourStatus);
  return freshHourStatus;
}

// import {
//   accessKeyId,secretAccessKey,region,tableName
// } from "./config";

// const AWS = require('aws-sdk');

// AWS.config.update({
//     accessKeyId: accessKeyId,
//     secretAccessKey: secretAccessKey,
//     region: region,
//     sslEnabled:false
// });

// const ddb = new AWS.DynamoDB({apiVersion: '2012-08-10'});



// export async function getItem(Date){
//     const getParams = {
//         TableName: `${tableName}`,
//         Key: {
//             'Farm' : {
//                 S:'internal-prod'
//             },
//             'Code':{
//                 N:`${Date}`
//             }
//         }
//     };
//     let res = await ddb.getItem(getParams).promise();
//     return res;
// }

// export async function postStatus(Item){
//         const putParams = {
//         TableName: `${tableName}`,
//         Item: Item
//     };
//     let res = ddb.putItem(putParams).promise();
//     return res;
// }

// export async function getLastStatus(){
//     let params = {
//         ExpressionAttributeValues: {
//             ":Farm": {
//                 S:"internal-prod"
//             }
//         },
//         KeyConditionExpression:"Farm=:Farm",
//         TableName: `${tableName}`,
//         ConsistentRead: true,
//         Select:"ALL_ATTRIBUTES",
//         ScanIndexForward:false,
//         Limit:1
//     };
//     let res = await ddb.query(params).promise();
//     return res;
// }

// export async function getPeriodStatus(toDate,fromDate){
//     let params = {
//         ExpressionAttributeValues: {
//             ":min": {
//                 N: `${fromDate}`
//             },
//             ":max":{
//                 N:`${toDate}`
//             },
//             ":Farm": {
//                 S:"internal-prod"
//             }
//         },
//         KeyConditionExpression: "Farm=:Farm and Code between :min and :max",
//         TableName: `${tableName}`,
//         ConsistentRead: true,
//         Select:"ALL_ATTRIBUTES",
//         ScanIndexForward:false
//     };
//     let res = await ddb.query(params).promise();
//     console.dir(res);
//     return res;
// }

//Get the hourly status in the past 24 hours
// export async function getPastStatus(currentDate){
//     //List the last 24 hours
//     let hours=[];
//     //List the exist hours in db
//     let existHours = [];
//     //List the exist hours status in db
//     let existHourStatus = [];

//     for(let i= 1;i<=24;i++){
//         hours.push(moment.utc(`${currentDate}`,'YYYYMMDDHH').subtract(i, 'h').format('YYYYMMDDHH').toString());
//     }
//     let yesterdayDate = moment.utc(`${currentDate}`,'YYYYMMDDHH').subtract(1, 'days').format('YYYYMMDDHH').toString();
//     await getPeriodStatus(currentDate,yesterdayDate).then(async (res)=>{
//         existHourStatus=res.Items;
//         existHourStatus.map((item)=>{
//             existHours.push(item.Code.N);
//             return 0;
//         });

//         await hours.map(async (h)=>{
//             let Item = {
//                 "Code":{
//                     N : `${h}`
//                 },
//                 "Farm":{
//                     S:'internal-prod'
//                 }
//             };
//             for (let i in service){
//                 Item[`${service[i]}`]={
//                     S:"ok"
//                 }
//             }
//             if(!existHours.includes(h)){
//                 //convert hour format to min format
//                 let toTime = h+'00';
//                 let fromTime = moment.utc(`${h}`,'YYYYMMDDHH').subtract(59, 'm').format('YYYYMMDDHHmm').toString();
//                 await getPeriodStatus(toTime,fromTime).then((res)=>{
//                     res.Items.map((item)=>{
//                         for (let i in service){
//                             let serviceName = service[i];
//                             if(Item[`${serviceName}`].S!=='down' && item[`${serviceName}`].S!=='ok'){
//                                 if(item[`${serviceName}`].S==='down'){
//                                     Item[`${serviceName}`].S='down';
//                                 }else{
//                                     Item[`${serviceName}`].S='interrupted';
//                                 }
//                             }
//                         }
//                         return 0;
//                     })
//                 });
//                 await postStatus(Item).then((res)=>{
//                     existHourStatus.push(Item);
//                     console.dir(res);
//                 });
//             }
//         })


//     });
//     return existHourStatus;
// }
