import React, { Component } from 'react'
import { ReactComponent as IconIncidentReport } from "../icons/icon-maintenance.svg";
import { Grommet, Box, Text, Tag } from "grommet";
import styled from 'styled-components';
import './index.css'
import CustomMenuTable from './CustomMenuTable';
import { OPS_DATA_URL, MAINTENANCE_STATUS } from "../config";
import { getLocalUTCTime, getTimeCompare, getTimeDuration } from '../utils/CommonUtil';

const IconCard = (props) => (
  <Box className='reportWin'>
    <Box direction='row' alignContent='start' className='reportLine'>
      <Box align="center" className='iconOuter'>
        <IconIncidentReport className='iconBox'></IconIncidentReport>
      </Box>
      <Box className='reportText' >
        <HighlightText >Planned maintenance schedule</HighlightText>
      </Box>
    </Box>
    <Box alignContent='start' direction='row' height="300px" className='searchTable'>
      <CustomMenuTable data={props.data} columns={props.columns} changeSelectValue={props.changeSelectValue} />
    </Box>
  </Box>
)

const HighlightText = styled(Text)`


  font-size: 100%;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.22px;
  text-transform: uppercase;
  color: #333333;
`

const HighlightTitleText = styled(Text)`
  
  height: 24px;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.22px;
  color: #333333;
`

const CustomTimeCard = (props) => (
  <Box >
    <Box direction="row-responsive">
      <Box direction="row">
        <Box >
          <Box style={{ margin: '6px' }}>
            <TitleText >Start time</TitleText>
          </Box>
          <Box style={{ margin: '6px', width: '260px' }}>
            <BodyText>{props.startTime}</BodyText>
          </Box>
        </Box>

        <Box>
          <Box style={{ margin: '6px' }}>
            <TitleText>End time</TitleText>
          </Box>
          <Box style={{ margin: '6px', width: '260px' }}>
            <BodyText>{props.endTime}</BodyText>
          </Box>
        </Box>
      </Box>
      <Box style={{ width: '90px' }} />
      <Box>
        <Box style={{ margin: '6px' }}>
          <TitleText>Duration</TitleText>
        </Box>
        <Box style={{ margin: '6px', width: '210px' }}>
          <BodyText>{props.duration}</BodyText>
        </Box>
      </Box>
    </Box>
  </Box>
)

const CustomContent = (props) => (
  <Box direction="row-responsive" style={{ marginTop: "10px", marginRight: "10px" }} width="80vm">
    <BodyDescText dangerouslySetInnerHTML={{ __html: props.value }} />
  </Box>
)

const BodyDescText = styled(Text)`
 
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  white-space: pre-wrap;
`

const BodyText = styled(Text)`
width: 100%;
height: 16px;
font-size: 16px;
font-weight: normal;
font-style: normal;
font-stretch: normal;
line-height: 1;
letter-spacing: normal;
color: #000000;
`

const TitleText = styled(Text)`
  width: auto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: rgba(0, 0, 0, 0.45);
`

export default class MaintenanceWin extends Component {

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      maintenanceDetail: [],
      columns: [{
        label: 'Subject',
        name: "subject",
        width: '100px',
        options: {
          sort: false
        }
      }, {
        label: 'id', name: 'id', options: {
          display: false,
          viewColumns: false,
          sort: false
        }
      }, {
        label: 'farm', name: 'farm', options: {
          display: false,
          viewColumns: false,
          sort: false
        }
      }, {
        label: 'start_time',
        name: 'start_time',
        options: {
          display: false,
          sortDirection: 'desc',
          viewColumns: false
        }
      }]
    }
  }

  componentDidMount() {
    this.getMaintenanceInfoList()
  }

  sortList = (a, b) => {
    return getTimeCompare(b.start_time, a.start_time)
  }

  getMaintenanceInfoList() {
    let farmCode = this.props.farmCode;
    let url = `${OPS_DATA_URL}`
    fetch(url + `/maintenances/list/${farmCode}`).then(res => {
      if (res.ok) {
        res.json().then(data => {
          let lastMaintenance = (data && data.length > 0) ? data.sort(this.sortList)[0] : {};
          this.setState({
            data: data,
            maintenanceDetail: lastMaintenance
          });
          this.props.updateLast(lastMaintenance);
        })
      }
    }
    )
  }

  changeSelectValue = (indexData, farmCode) => {
    let url = `${OPS_DATA_URL}`
    fetch(url + `/maintenances/${indexData}`).then(res => {
      if (res.ok) {
        res.json().then(data => {
          this.setState({
            maintenanceDetail: data
          })
        })
      }
    })
  }
  render() {
    const { data, columns, maintenanceDetail } = this.state;
    let subject = maintenanceDetail.subject ? maintenanceDetail.subject : 'Currently not scheduled';
    let farm = maintenanceDetail.farm ? maintenanceDetail.farm : '';
    let start_time = maintenanceDetail.start_time ? getLocalUTCTime(maintenanceDetail.start_time) : '';
    let end_time = maintenanceDetail.end_time ? getLocalUTCTime(maintenanceDetail.end_time) : '';
    let duration = maintenanceDetail.end_time ? getTimeDuration(maintenanceDetail.end_time, maintenanceDetail.start_time) : '';
    let ci_status = maintenanceDetail.ci_status ? maintenanceDetail.ci_status : undefined;
    let current_status_item = MAINTENANCE_STATUS.find(item => item.label === ci_status);
    let status_color = "gray";
    if (current_status_item) {
      status_color = current_status_item.color;
    }
    return (
      <Grommet>
        <Box justify="evenly" direction="row-responsive" style={{ marginTop: '14.5px' }} >
          <IconCard data={data} columns={columns} changeSelectValue={this.changeSelectValue} />
          <div className='contentWidth'>
            <Box direction="row-responsive" width="757px" height={{ min: "400px" }} >
              <Box align="start" alignContent="start" style={{ marginTop: '10px' }}>
                <Box style={{ marginBottom: '5px' }} fill="horizontal" direction="row-responsive" justify="between">
                  <Box>
                    <TitleText>{start_time}</TitleText>
                  </Box>
                  <Box>
                    {ci_status ?
                      <Tag value={ci_status}
                        size="xsmall"
                        round="large"
                        style={{ color: "#ffffff" }}
                        background={{ color: status_color }} /> : <Box />
                    }
                  </Box>
                </Box>
                <Box direction="row-responsive">
                  <Box style={{ width: '567px' }} justify="center">
                    <HighlightTitleText>{farm.toUpperCase()} - {subject.toUpperCase()}</HighlightTitleText>
                  </Box>
                </Box>
                <CustomTimeCard type="Planned maintenance" startTime={start_time} endTime={end_time} duration={duration} ci_status={ci_status} />
                <CustomContent value={maintenanceDetail.desc} />
              </Box>
            </Box>
          </div>
        </Box>
      </Grommet>
    )
  }
}