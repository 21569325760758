import React, { Component } from 'react';
import { Form, Input, DatePicker, Select } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './index.css';
import { getNowFormatDate } from '../utils/CommonUtil';
import { DATE_PICKER_FORMAT, FARM_ALIAS, MAINTENANCE_STATUS, INCIDENT_STATUS } from '../config';

const { Option } = Select;

// const formats = [
//   'font','size',
//   'bold','italic','underline','strike',
//   'color','background',
//   'script',
//   'header','blockquote','code-block',
//   'indent','list',
//   'direction','align',
//   'link','image','video','formula',
// ]

const modules = {
  toolbar: [
    [{ 'header': [ 1, 2, 3, false] }],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image'],
    ['clean']
  ],
}

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
]

const CustomizedForm = Form.create({
  name: 'global_state',
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    return {
      farm: Form.createFormField({
        ...props.farm,

      }),
      subject: Form.createFormField({
        ...props.subject,

      }),
      desc: Form.createFormField({
        ...props.desc,

      }),
      start_time: Form.createFormField({
        ...props.start_time,

      }),
      end_time: Form.createFormField({
        ...props.end_time,

      }),
      ci_status: Form.createFormField({
        ...props.ci_status,

      })
    };
  },
  onValuesChange(_, values) {
    // console.log(values);
  },
})(
  class extends Component {
    // handleChange(date, dateString) {
    //   console.log("date is", date, dateString)
    // }

    render() {
      const { form, isEdit, type } = this.props
      const CHANGE_STATUS = {"Incident": INCIDENT_STATUS, "Maintenance": MAINTENANCE_STATUS}[type];
      const { getFieldDecorator } = form;
      const formItemLayout = {
        labelCol: {
          xs: { span: 6 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 6 },
          sm: { span: 15 },
        },
      };
      return (
        <Form {...formItemLayout}>
          <div>
            <Form.Item hasFeedback label="Start time" htmlFor='start_time'>
              {getFieldDecorator('start_time',
                {
                  initialValue: getNowFormatDate(this.props.start_time),
                  rules: [{ type: 'object', required: true, message: 'Please select start time!' }]
                })(
                  <DatePicker showTime format={DATE_PICKER_FORMAT} style={{ width: '300px' }} />,
                )}
            </Form.Item>
            <Form.Item hasFeedback label="End time" htmlFor='end_time'>
              {getFieldDecorator('end_time',
                {
                  initialValue: getNowFormatDate(this.props.end_time),
                  rules: [{ type: 'object', required: true, message: 'Please select end time!' }]
                })(
                  <DatePicker showTime format={DATE_PICKER_FORMAT} style={{ width: '300px' }} />,
                )}
            </Form.Item>
          </div>
          {isEdit ?
            <Form.Item hasFeedback label="Farm" htmlFor='farm'>
              {getFieldDecorator('farm', {
                initialValue: this.props.farm || undefined,
                rules: [{ required: true, message: 'Farm is required!' }],
              })(
                <Select disabled>
                  {FARM_ALIAS.map(farm => <Option value={farm}>{farm.toUpperCase()}</Option>)}
                </Select>
              )
              }
            </Form.Item> : <Form.Item hasFeedback label="Farm" htmlFor='farm'>
              {getFieldDecorator('farm', {
                initialValue: ['us2'],
                rules: [{ type: 'array', required: true, message: 'Farm is required!' }],
              })(
                <Select mode="multiple" placeholder="Please select farm">
                  {FARM_ALIAS.map(farm => <Option value={farm}>{farm.toUpperCase()}</Option>)}
                </Select>
              )}
            </Form.Item>
          }
          {isEdit ?
            <Form.Item hasFeedback label="Subject" htmlFor='subject'>
              {getFieldDecorator('subject', {
                initialValue: this.props.subject || undefined,
                rules: [{ required: true, message: 'subject is required!' },
                { max: 50, message: 'subject must be lower than 50 characters' }],
              })(<Input placeholder='enter subject' />)}
            </Form.Item> : <Form.Item hasFeedback label="Subject" htmlFor='subject'>
              {getFieldDecorator('subject', {
                rules: [{ required: true, message: 'subject is required!' },
                { max: 50, message: 'subject must be lower than 50 characters' }],
              })(<Input placeholder='enter subject' />)}
            </Form.Item>
          }
          {isEdit ?
            <Form.Item hasFeedback label="Description" htmlFor='desc'>
              {getFieldDecorator('desc', {
                initialValue: this.props.desc || undefined,
                rules: [{ required: true, message: 'description is required!' }],
              })(<ReactQuill theme="snow" formats={formats} modules={modules} height="500px" />)}
            </Form.Item>
            :
            <Form.Item hasFeedback label="Description" htmlFor='desc'>
              {getFieldDecorator('desc', {
                rules: [{ required: true, message: 'description is required!' }],
              })(<ReactQuill theme="snow" formats={formats} modules={modules} height="500px" />)}
            </Form.Item>
          }
          {isEdit ?
            <Form.Item hasFeedback label={`${type} Status`} htmlFor='change_status'>
              {getFieldDecorator('ci_status', {
                initialValue: this.props.ci_status || undefined,
                rules: [{ required: true, message: `${type} Status is required!` }],
              })(
                <Select>
                  {CHANGE_STATUS.map(status_item => <Option style={{color: status_item.color}} value={status_item.label}>{status_item.label}</Option>)}
                </Select>
              )
              }
            </Form.Item> : <Form.Item hasFeedback label={`${type} Status`} htmlFor='change_status'>
              {getFieldDecorator('ci_status', {
                initialValue: undefined,
                rules: [{ required: true, message: `${type} Status is required!` }],
              })(
                <Select placeholder="Please select status">
                  {CHANGE_STATUS.map(status_item => <Option style={{color: status_item.color}} value={status_item.label}>{status_item.label}</Option>)}
                </Select>
              )}
            </Form.Item>
          }
        </Form>
      );
    }
  });

export default CustomizedForm
