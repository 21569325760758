import React, { Component } from 'react';
import { Form, InputNumber, Button, Select, DatePicker, message } from 'antd';
import { Box } from 'grommet';
import { OPS_DATA_URL, FARM_ALIAS } from '../config';
import { getAuthEmailFromToken, authenticateToken } from '../utils/auth';

const moment = require('moment');
const { Option } = Select;
const { MonthPicker } = DatePicker;

class SLAForm extends Component {

  constructor() {
    super();
    this.state = {
      saving: false
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return console.log(err);
      }
      this.setState({ saving: true });
      let slaItem = values;
      let authToken = authenticateToken();
      let operator = getAuthEmailFromToken();
      slaItem.operator = operator;
      slaItem.month = slaItem.month.format("YYYY-MM");
      console.log('Received values of form: ', slaItem);
      var fetchOption = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authToken
        },
        body: JSON.stringify(slaItem)
      }
      let url = `${OPS_DATA_URL}/slaitems`
      fetch(url, fetchOption).then(res => {
        this.setState({ saving: false });
        message.success('SLA has been saved');
      }).catch((err) => {
        this.setState({ saving: false });
        message.error(err);
      })


    })
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { saving } = this.state;
    return (
      <Box width="811px" align="start" style={{ margin: "120px auto 0" }}>
        <Form onSubmit={this.handleSubmit} >
          <Form.Item label="SMAX SaaS Farm">
            {getFieldDecorator('farm', {
              initialValue: FARM_ALIAS[0],
            })(<Select placeholder="Please select farm!">
              {FARM_ALIAS.map(farm => <Option value={farm}>{farm.toUpperCase()}</Option>)}
            </Select>)}
          </Form.Item>
          <Form.Item label="Month">
            {getFieldDecorator('month', {
              initialValue: moment().subtract(1, 'months') || "",
              rules: [{ required: true, message: 'Please input the month!' }],
            })(<MonthPicker placeholder="Select Month" />)}
          </Form.Item>
          <Form.Item label="SLA">
            {getFieldDecorator('slaValue', {
              initialValue: 0.999,
              rules: [{ required: true, message: 'Please input the availability!' }],
            })(<InputNumber min={0.01} max={1} step={0.001} />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={saving}>Save</Button>
          </Form.Item>
        </Form>
      </Box>
    );
  }
}
const WrappedSLAForm = Form.create()(SLAForm);
export default WrappedSLAForm;