import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card'
import { ReactComponent as StatusIcon } from './icons/circle-fill.svg';
import { APPMODE, MONITOR_DATA_URL, OPS_DATA_URL } from "../../config";
import './App.css';
import axios from 'axios';


const FarmCard = (props) => {
  const name = props.farm.name;
  let [status, setStatus] = useState("yellow");
  let [version, setVersion] = useState("");

  useEffect(() => {

    async function fetchData() {
      const loginStatusURL = `${MONITOR_DATA_URL}/login/status/${name}`;
      const loginStatus = await axios.get(loginStatusURL);
      if (loginStatus && Array.isArray(loginStatus.data) && loginStatus.data.length > 0) {
        if (APPMODE === "ESM") {
          setStatus(loginStatus.data[0].SMAX_LOGIN);
        } else if (APPMODE === "DCA") {
          setStatus(loginStatus.data[0].DCA_LOGIN_DCA);
        }
      }

      const versionUrl = `${OPS_DATA_URL}/configitems/${name}`
      const version = await axios.get(versionUrl);
      if (version && version.data) {
        setVersion(version.data.smaxver)
      }
    }
    fetchData();
    return () => {
      setStatus({});
    };
  }, [name])

  return (
    <Card border="light" bg='' style={{ width: '248px', height: '158px' }} >
      <Card.Body>
        <Card.Title>
          <span className='FarmHeader'>
            {APPMODE === "DCA" ?
              <a href={`/dashboard/?farm=${props.farm.name}`}>FARM:{props.farm.location}{' '}{name.toUpperCase()}</a>
              :
              <>FARM:{props.farm.location}{' '}{name.toUpperCase()}</>
            }
          </span>
        </Card.Title>
        <Card.Text>
          <span className='version'>Version:{' '}{version}</span><br />
          <span className='service-status'>Service status:{'  '} </span><StatusIcon style={{ 'fontSize': '2px', 'color': status }}></StatusIcon>
          <br />
          <span className='data-from-last'> Data from last 24 hours</span><br />
          {APPMODE === "DCA" ?
            <></> : <a href={`/dashboard/?farm=${props.farm.name}`}>View this farm</a>
          }
        </Card.Text>
      </Card.Body>
    </Card>
  )
}


export default FarmCard;