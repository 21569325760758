import React, { Component } from 'react';

import { getLocalHour, isMainHour } from "../utils/CommonUtil";
import { StatusGoodSmall } from 'grommet-icons';
class StatusIcon extends Component {

  render() {
    const { status, time } = this.props;


    const localtime = getLocalHour(time);

    const pointStyle = {
      height: "41px"
    }

    const okColor = "#1aac60";
    const warnColor = "#ffc002";
    const errorColor = "#e5004c";
    const nodataColor = "#bdbec0";

    const bigok = (<StatusGoodSmall color={okColor} size="8px" style={pointStyle} />);
    const bigwarn = (<StatusGoodSmall color={warnColor} size="8px" style={pointStyle} />);
    const bigerror = (<StatusGoodSmall color={errorColor} size="8px" style={pointStyle} />);
    const bignodata = (<StatusGoodSmall color={nodataColor} size="8px" style={pointStyle} />);

    const smallok = (<StatusGoodSmall color={okColor} size="5px" style={pointStyle} />);
    // const smallwarn = (<StatusGoodSmall color={warnColor} size="9px" style={pointStyle} />);
    // const smallerror = (<StatusGoodSmall color={errorColor} size="9px" style={pointStyle} />);
    const smallnodata = (<StatusGoodSmall color={nodataColor} size="5px" style={pointStyle} />);

    switch (status) {
      case 'green':
        if (isMainHour(localtime)) {
          return (bigok);
        } else {
          return (smallok);
        }
      case 'red':
        return (bigerror);
      case 'error':
        return (bigerror);
      case 'nodata':
        if (isMainHour(localtime)) {
          return (bignodata);
        } else {
          return (smallnodata);
        }
      case 'yellow':
        if (isMainHour(localtime)) {
          return (bigwarn);
        } else {
          return (bigwarn);
        }
      default:
        return (bignodata);
    }
  }
}

export default StatusIcon;