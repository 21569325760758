import { userPoolWebClientId } from "../config"

const getCognitoAttrPrefix = () => {
  const ClientPrefix = `CognitoIdentityServiceProvider.${userPoolWebClientId}`;
  let lastUserkey = `${ClientPrefix}.LastAuthUser`;
  let lastAuthUserId = localStorage.getItem(lastUserkey);
  return `${ClientPrefix}.${lastAuthUserId}`;
}

export const authenticateToken = () => {
  let idTokenKey = `${getCognitoAttrPrefix()}.idToken`;
  let idToken = localStorage.getItem(idTokenKey);
  return idToken;
}

export const getAuthEmailFromToken = () => {
  let userDataKey = `${getCognitoAttrPrefix()}.userData`;
  let userData = localStorage.getItem(userDataKey);
  let attr = JSON.parse(userData).UserAttributes;
  for(let item of attr){
    if(item.Name === "email"){
      return item.Value;
    }
  }
}
