import React, { Component } from 'react'
import { Alert } from 'antd'
import { Box, Grommet } from 'grommet'

export default class NotificationStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: props.text,
      type: props.type,
      visible: props.visible
    }
  }

  handleClose = () => {
    this.setState({
      visible: false
    })
  }

  render() {
    const { text, type } = this.state;
    return (
      <Grommet>
        <Box pad="small" basis="large" width="xlarge" fill="vertical">
          <div>{
            this.state.visible ? (
              <Alert message={text} type={type} closable afterClose={this.handleClose} showIcon></Alert>
            ) : null} </div>
        </Box>
      </Grommet>
    )
  }
}
