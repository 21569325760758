import moment from 'moment';
import { DATE_PICKER_FORMAT } from '../config';

const cardTimeFormat = 'MMM DD, YYYY HH:mm:ss';
const dayFormat = 'MMM DD';
const timeOffset = new Date().getTimezoneOffset() / 60;

/**
 * Get a uniform time format
 * @param {Date} date
 */
export const getFormattedDate = (date) => {
  let offset = moment().utcOffset();
  return moment(date).utcOffset(offset).format(cardTimeFormat);
}

export const getToDay = (date) => {
  let offset = moment().utcOffset();
  return moment(date).utcOffset(offset).format(dayFormat);
}

export const getYesterDay = (date) => {
  let offset = moment().utcOffset();
  return moment(date).utcOffset(offset).subtract(1, 'days').format(dayFormat);
}

export const getPreviousTimestamp = (date, num) => {
  return moment(date).subtract(num, 'days').valueOf();
}

export const getNextTimestamp = (date, num) => {
  return moment(date).add(num, 'days').valueOf();
}

export const getNewDateWidth = (date) => {
  let offset = moment().utcOffset();
  let hour = moment(date).utcOffset(offset).format('HH');
  let diff = Number.parseInt(hour);
  if (diff === 0) {
    return 26 + 38 * 0.5;
  }
  let width = 26 + 38;
  width += Number.parseInt(diff / 3) * 2 * 26;
  if (diff % 3 === 0) {
    width += (Number.parseInt(diff / 3) - 0.5) * 38;
  } else {
    width += Number.parseInt(diff / 3) * 38;
    width += ((diff % 3) - 0.5) * 26;
  }
  return width;
}

/**
 * set cookie value
 * @param {cookie} name
 * @param {cookie} value
 */
export const setCookie = (name, value) => {
  var Mins = 30;
  var exp = new Date();
  exp.setTime(exp.getTime() + Mins * 60 * 1000);
  document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();

}

/**
 * get login cookie
 * @param {cookie name} name
 */
export const getCookie = (name) => {

  var strCookies = document.cookie;
  var array = strCookies.split(';');
  for (var i = 0; i < array.length; i++) {
    var item = array[i].split("=");
    // console.log('item',item[0].trim())
    // console.log('name',name)
    if (item[0].trim() === name) {
      return item[1];
    }
  }
  return null;
}

/**
 * Compare two dates
 * @param {Date} timea
 * @param {Date} timeb
 */
export const getTimeCompare = (timea, timeb) => {
  return moment(timea).format('x') - moment(timeb).format('x');
}

/**
 * Get the duration between two dates
 * @param {Date} timea
 * @param {Date} timeb
 */
export const getTimeDuration = (timea, timeb) => {
  let dura = getTimeCompare(timea, timeb);
  return millisecondToDate(dura);
}

/**
 * Get the mark of the timezone offset
 */
export const getTimezoneOffsetMark = () => {
  let offsetFlag = "";
  if (timeOffset < 0) {
    offsetFlag = "+";
  }
  else if (timeOffset > 0) {
    offsetFlag = "-";
  }
  return offsetFlag + Math.abs(timeOffset)
}

/**
 * Get the current local hour
 */
export const getCurrentLocalHour = () => {
  let currentDate = new Date();
  let currentHour = moment.utc(currentDate).format('HH');
  return getLocalHour(currentHour)
}

/**
 * Get the local hour by utc hour
 */
export const getLocalHour = (utchour) => {
  let currentHour = Number.parseInt(utchour);
  let localHour = currentHour - timeOffset;
  if (localHour > 23) {
    localHour -= 24;
  }
  if (localHour < 0) {
    localHour += 24;
  }
  return localHour
}

/**
 * Get the local hour text by utc hour
 */
export const getLocalHourText = (utchour) => {
  let currentHour = Number.parseInt(utchour);
  let localHour = currentHour - timeOffset;
  if (localHour > 23) {
    localHour -= 24;
  }
  if (localHour < 0) {
    localHour += 24;
  }
  if (localHour < 10) {
    localHour = "0" + localHour;
  }
  return localHour
}

/**
 * Check the hour is the main hour
 * @param {Number} hour
 */
export const isMainHour = (hour) => {
  if (!isNaN(hour)) {
    // if (hour === 0) {
    //   return true
    // }
    let currentLocalHour = getCurrentLocalHour();
    let offset = currentLocalHour - hour;
    if (Math.abs(offset) % 3 === 0) {
      return true
    }
  }
  return false
}

/**
 * get now format date
 */

export const getNowFormatDate = (timestamp) => {
  // var date = new Date();
  // var seperator1 = "-";
  // var seperator2 = ":";
  // var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  // var strDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  // var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
  //   + " " + date.getHours() + seperator2 + date.getMinutes()
  // return currentdate;
  let offset = moment().utcOffset()
  return moment(timestamp).utcOffset(offset);
}

export const getLocalUTCTime = (timeInput) => {
  let offset = moment().utcOffset();
  let timeOutput = moment(timeInput).utcOffset(offset);
  return timeOutput.format(DATE_PICKER_FORMAT)
}

export const getNowTimestamp = (time) => {
  if (time)
    return moment(time).valueOf();
  else
    return moment().valueOf();
}

/**
 * transfer millisecond to date
 */
export default function millisecondToDate(msd) {
  var time = parseFloat(msd) / 1000;
  var hour = 0
  var minute = 0
  var second = 0
  if (null !== time && "" !== time) {
    if (time > 60 && time < 60 * 60) {
      minute = parseInt(time / 60.0)
      second = parseInt((parseFloat(time / 60.0) - parseInt(time / 60.0)) * 60)
    }
    else if (time >= 60 * 60 && time < 60 * 60 * 24) {
      hour = parseInt(time / 3600.0)
      minute = parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)
      second = parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
        parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60)

    } else if (time >= 60 * 60 * 24) {
      var day = parseInt(time / 3600.0 / 24)
      var temphour = parseInt((parseFloat(time / 3600.0 / 24) - day) * 24)
      minute = parseInt(parseFloat(time / 60.0 - day * 24 * 60 - temphour * 60))
      second = parseInt(parseFloat(time - day * 24 * 3600 - temphour * 3600 - minute * 60))
      hour = day * 24 + temphour
    }
    else {
      second = Math.round(time)
    }

    if (hour < 10) {
      if (hour === 0) {
        hour = '00'
      } else {
        hour = '0' + hour
      }
    }
    if (minute < 10) {
      if (minute === 0) {
        minute = '00'
      } else {
        minute = '0' + minute
      }
    }
    if (second < 10) {
      if (second === 0) {
        second = '00'
      } else {
        second = '0' + second
      }
    }

    time = hour + ':' + minute + ':' + second
  }
  return time
}
