import React from 'react';
import Header from './Header';
import Footer from './Footer';
import FarmCards from './FarmCards'
// import MapView from './MapView';
import 'bootstrap/dist/css/bootstrap.min.css';
import Map from './Map';

const Portal = () => {
    return (
        <>
            <div id='content-wrap'>
                <Header />
                {/* <MapView /> */}
                <Map />
                <FarmCards />
            </div>
            <Footer />
        </>

    )
}

export default Portal;