import React, { Component } from 'react';
import MUIDataTable from "mui-datatables";
import { Modal } from 'antd';
import { OPS_DATA_URL, FARM_ALIAS } from "../../config";
import { getSLAHistory } from "../../utils/DBUtils";

const HISTORY_URLS = `${OPS_DATA_URL}/slaitems/list`;

class SLAHistory extends Component {

  constructor(props) {
    super(props);
    this.state = { visible: false, data: [] };
  }

  componentDidMount(props) {
    // console.log("farmIndex="+HISTORY_URLS[this.props.farmIndex]);
    getSLAHistory(`${HISTORY_URLS}/${FARM_ALIAS[this.props.farmIndex]}`).then((res) => {
      this.setState({
        data: res
      })
    });

  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    const columns = ["SLA", "Month", "Year"];

    const options = {
      filterType: "dropdown",
      responsive: "scroll",
      search: false,
      print: false,
      viewColumns: false,
      selectableRows: "none"
    };

    return (
      <Modal
        title="SLA HISTORY"
        visible={this.state.visible}
        onOk={this.handleOk}
        onCancel={this.handleOk}
        footer={null}
      >
        <MUIDataTable
          data={this.state.data}
          columns={columns}
          options={options}
        />
      </Modal>

    );
  }
}

export default SLAHistory;
